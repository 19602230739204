class BackToTop extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('click', this.backToTop.bind(this), false);
  }

  connectedCallback() {
    window.addEventListener('scroll', this.updateScrollPercentage.bind(this));
  }

  backToTop() {
    if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  updateScrollPercentage() {
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    this.style.setProperty('--scroll-percentage', scrollPercentage.toFixed(2) + '%');
    if (scrollTop > 200) {
      this.classList.add('show');
    } else {
      this.classList.remove('show');
    }
  }
}

export default BackToTop;
